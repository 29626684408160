import React from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
const TableHeader = ({ sortKey, sortBy, sortDirection, label, sort }) => {
  return (
    <th
      className="hover:cursor-pointer"
      onClick={() => {
        sort({ sortBy: sortKey, sortDirection: !sortDirection });
      }}
    >
      <div className="flex items-center">
        {label}
        {sortBy == sortKey ? (
          sortDirection ? (
            <BsArrowUp />
          ) : (
            <BsArrowDown />
          )
        ) : null}
      </div>
    </th>
  );
};

export default TableHeader;
