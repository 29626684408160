import * as React from "react";
import "./index.css";
import Datafeed from "./api/rp-datafeed";
import { widget } from "../../components/charting_library";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
let MACDEntity = null;
export class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: "FAF",
    interval: "1D",
    containerId: "tv_chart_container",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "http://localhost:8080",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    timezone: "exchange",
  };

  tvWidget = null;

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const x = this.props.isDark ? "dark" : "light";
    const S = "dark" == x ? "#111" : "#EEE";

    const widgetOptions = {
      debug: true,
      symbol: this.props.symbol,
      datafeed: Datafeed,
      interval: this.props.interval,
      container: this.ref.current,
      library_path: this.props.libraryPath,

      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      time_frames: [
        { text: "1y", resolution: "1D", description: "1 Year" },
        { text: "3m", resolution: "1D", description: "3 Months" },
        { text: "1m", resolution: "1D", description: "1 Month" },
        { text: "1w", resolution: "60", description: "1 Week" },
        { text: "1d", resolution: "5", description: "1 Day" },
        { text: "1000y", resolution: "1D", description: "All", title: "All" },
      ],
      watchlist: [this.props.symbol],
      overrides: {
        "mainSeriesProperties.candleStyle.upColor": "#08bca8",
        "mainSeriesProperties.candleStyle.downColor": "#ff6962",
        "mainSeriesProperties.candleStyle.wickUpColor": "rgba(9, 188, 168, 1)",
        "mainSeriesProperties.candleStyle.wickDownColor":
          "rgba(255, 105, 98, 1)",
        "mainSeriesProperties.candleStyle.drawBorder": false,
        "mainSeriesProperties.showCountdown": true,
        "symbolWatermarkProperties.color": "rgba(177, 182, 185, 0.4)",
        "paneProperties.vertGridProperties.color": S,
        "paneProperties.horzGridProperties.color": S,
      },
      theme: x,
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          // tvWidget.showNoticeDialog({
          //   title: "Notification",
          //   body: "TradingView Charting Library API works correctly",
          //   callback: () => {
          //     console.log("Noticed!");
          //   },
          // })
          {
            if(MACDEntity == null) {

              tvWidget
                .activeChart()
                .createStudy("MACD", false, false, {
                  in_0: 14,
                  in_1: 30,
                  in_3: "close",
                  in_2: 9,
                }).then((eid)=>{
                  MACDEntity = eid
                });
            } else {
              console.log(MACDEntity);
              tvWidget.activeChart().removeEntity(MACDEntity)
              MACDEntity = null;
            }
          }
        );

        button.innerHTML = "MACD";
      });
    });
  }
  async componentDidUpdate(prevProps) {
    await console.log(this.tvWidget);
    const widget = this.tvWidget;
    const symbol = this.props.symbol;
    const isDark = this.props.isDark;
    const setSelectedStock = this.props.setSelectedStock;
    this.tvWidget.onChartReady(function () {
      widget.activeChart().setSymbol(symbol, () => {
        console.log("Updated Symbol");
      });
      widget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, (d) => {
          setSelectedStock({ symbol: d.name });
        });
      isDark ? widget.changeTheme("dark") : widget.changeTheme("light");
    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return (
      <div
        ref={this.ref}
        className={"TVChartContainer"}
        style={{ minHeight: "500px" }}
      />
    );
  }
}
