import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const MoreInfoModal = ({ symbol, moreInfo, isOpen, onClose }) => {
  const getColor = (val) => {
    return val === "BULLISH"
      ? "green-primary"
      : val === "BEARISH"
      ? "red-primary"
      : "white";
  };

  const ma = [
    {
      period: "MA 10",
      key: "ten",
    },
    {
      period: "MA 20",
      key: "twenty",
    },
    ,
    {
      period: "MA 50",
      key: "fifty",
    },
    ,
    {
      period: "MA 100",
      key: "hundred",
    },
    ,
    {
      period: "MA 200",
      key: "twohundred",
    },
  ];
  return (
    <div className="w-full">
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div class={"flex flex-col"}>
             {symbol}
              <span className="text-xs">Technical analysis</span>
              <span className="text-tiny">
                As of {new Date().toDateString()}
              </span>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="">
              <div className="">
                <div className="w-full flex justify-around text-sm mb-10">
                  <div className="flex justify-around w-full">
                    <h1>Week to Date %</h1>
                    <p>{moreInfo?.WEEKTODATE}</p>
                  </div>
                  <div className="flex justify-around w-full">
                    <h1>Month to Date %</h1>
                    <p>{moreInfo?.MONTHTODATE}</p>
                  </div>
                  <div className="flex justify-around w-full">
                    <h1>Year to Date %</h1>
                    <p>{moreInfo?.YEARTODATE}</p>
                  </div>
                </div>
                <div className="flex justify-between px-10">
                  <div className="flex flex-col w-[45%]">
                    <p className={"text-sm"}>Moving Averages</p>
                    <div className="flex uppercase text-sm justify-evenly">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Period</th>
                            <th>Simple</th>
                            <th>Exponential</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ma.map(({ period, key }, index) => (
                            <tr key={index} className="align-top h-[60px]">
                              <td>{period}</td>
                              <td>
                                <div>
                                  <p>{moreInfo?.SMA[key]}</p>
                                  <p
                                    className={`text-${getColor(
                                      moreInfo?.SMA[key + "_trend"]
                                    )} `}
                                  >
                                    ({moreInfo?.SMA[key + "_trend"]})
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p>{moreInfo?.EMA[key]}</p>
                                  <p
                                    className={`text-${getColor(
                                      moreInfo?.EMA[key + "_trend"]
                                    )} `}
                                  >
                                    ({moreInfo?.EMA[key + "_trend"]})
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="flex flex-col w-[45%]">
                    <p className={"text-sm"}>Indicators</p>
                    <div className="flex uppercase text-sm justify-evenly">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th className="">Value</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="align-top h-[70px] text-left text-[12px]">
                            <td className="font-bold w-[10%] ">RSI (14)</td>
                            <td className="text-center">{moreInfo?.RSI}</td>
                            <td
                              className={`text-${getColor(
                                moreInfo?.RSI_TREND
                              )} `}
                            >
                              {moreInfo?.RSI_TREND}
                            </td>
                          </tr>
                          <tr className="align-top h-[70px] text-left text-[12px]">
                            <td className="font-bold w-[10%] ">
                              MACD (12, 26, 9)
                            </td>
                            <td className="text-center">
                              ({moreInfo?.MACD?.a}, {moreInfo?.MACD?.b})
                            </td>
                            <td
                              className={`text-${getColor(
                                moreInfo?.MACD_TREND
                              )} `}
                            >
                              {moreInfo?.MACD_TREND
                                ? moreInfo?.MACD_TREND
                                : "-"}
                            </td>
                          </tr>
                          <tr className="align-top h-[70px] text-left text-[12px]">
                            <td className="font-bold w-[10%] ">ATR (14)</td>
                            <td className="text-center">{moreInfo?.ATR}</td>
                            <td>
                              {moreInfo?.ATR_TREND ? moreInfo?.ATR_TREND : "-"}
                            </td>
                          </tr>
                          <tr className="align-top h-[70px] text-left text-[12px]">
                            <td className="font-bold w-[10%] ">CCI (20)</td>
                            <td className="text-center">{moreInfo?.CCI}</td>
                            <td
                              className={`text-${getColor(
                                moreInfo?.CCI_TREND
                              )} `}
                            >
                              {moreInfo?.CCI_TREND}
                            </td>
                          </tr>
                          <tr className="align-top h-[70px] text-left text-[12px]">
                            <td className="font-bold w-[10%] ">
                              Williams %R (14)
                            </td>
                            <td className="text-center">{moreInfo?.WILLR}</td>
                            <td
                              className={`text-${getColor(
                                moreInfo?.WILLR_TREND
                              )} `}
                            >
                              {moreInfo?.WILLR_TREND}
                            </td>
                          </tr>
                          <tr className="align-top h-[70px] text-left text-[12px]">
                            <td className="font-bold w-[10%] ">
                              VolumeSMA (15)
                            </td>
                            <td className="text-center">{moreInfo?.VWMA}</td>
                            <td
                              className={`text-${getColor(
                                moreInfo?.VWMA_TREND
                              )} `}
                            >
                              {moreInfo?.VWMA_TREND}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MoreInfoModal;
