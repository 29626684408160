import axios from "axios";
import config from "../config";

export const getMarketInfo = async () => {
  const resp = await axios.get(`${config.apiUrl}/market-info`);
  return resp.data.symbols;
};
export const getStockHistory = async (symbol, from, to, countBack,resolution) => {
  const resp = await axios.get(
    `${config.apiUrl}/history?symbol=${symbol}&from=${from}&to=${to}&countBack=${countBack}&resolution=${resolution}`
  );
  return resp.data;
};
export const getAllStocks = async (page) => {
  const resp = await axios.get(`${config.apiUrl}/stocks`, {
    params: {
      page: page,
      market_indicator: "normal",
    },
  });
  return resp.data.results.allStocks;
};

export const getWatchList = async () => {
  return await axios
    .get(`${config.apiUrl}/watchlist`, {
      params: {
        email: "mytrade_test@gmail.com",
      },
    })
    .then((res) => {
      return res.data?.status == 400 ? "" : res.data;
    })
    .catch((err) => console.log(err));
};

export const updateWatchlist = async (form) => {
  return await axios.post(`${config.apiUrl}/watchlist/update`, form)
  .then((resp)=>{
	if (resp.data.status == 400) {
		console.log(resp.data.results);
    return {
      message: resp.data.results.error.message,
      status: 'error',
      resp
    };
	} else {
		return {
			message: `Updated ${form.name} watchlist name to ${form.newName}`,
			status: 'success' ,
      resp
		}
	}
  });
}

export const deleteWatchlist = async (form) => {
  return await axios.post(`${config.apiUrl}/watchlist/delete`, form)
  .then((resp)=>{
	if (resp.data.status == 400) {
		console.log(resp.data.results);
    return {
      message: resp.data.results.error.message,
      status: 'error',
      resp
    };
	} else {
		return {
			message: `Deleted watchlist group ${form.name}!`,
			status: 'success' ,
      resp
		}
	}
  });
}

export const getBidAndAsk = async (symbol) => {
  return await axios
    .get(`${config.apiUrl}/bids-asks?symbol=${symbol}`)
    .then((res) => {
      return res;
    });
};
export const getTransactions = async (params) => {
  const resp = await axios.get(`${config.apiUrl}/transactions`, {
    params: {
      symbols: JSON.stringify(params.symbols ? params.symbols : []),
      limit: params.limit ? params.limit : 20,
      page: params.page ? params.page : 1,
    },
  });
  return resp.data.results.data;
};

export const addToWatchlist = async (form) => {
  return await axios.post(`${config.apiUrl}/watchlist/add`, form)
  .then((resp)=>{
	if (resp.data.status == 400) {
		console.log(resp.data.results);
	} else {
		return {
			message: `Added ${form.symbol} to Watch list`,
			status: 'success' ,
      resp
		}
	}
  });
};

export const removeWatchListItem = async (form) => {
  return axios
    .post(`${config.apiUrl}/watchlist/remove`, form)
    .then((res) => {
      res.data.status === 400
        ? console.log(res.data.results)
        : console.log('Successfully removed'); return {
          status: "success",
          message: `Removed ${form?.symbol} to Watchlist`,
          resp: res
        };
    })
    .catch((err) => console.log(err, "err"));
};

export const createWatchlistGroup = async (form) => {
  return await axios.post(`${config.apiUrl}/watchlist`, form)
    .then((res) => {
      if(res.data.status == 400) {
        console.log(res.data.results)
        return {
          status: 'error',
          message: res.data.results.error.message,
          resp: res
        }
      } else {
        return {
          status: 'success',
          message: 'Created a new group',
          resp: res
        }
      }
    })
}

export const getStockMoreInfo = async (symbol) => {
  const resp = await axios.get(`${config.apiUrl}/stock/more-info`, {
    params: {
      symbol: symbol,
    },
  });

  const result = {};
  const obj = resp.data.results.data;

  for (const key in obj) {
    if (typeof obj[key] === "number" && obj[key] % 1 !== 0) {
      result[key] = obj[key].toFixed(4);
    } else if (
      typeof obj[key] === "string" ||
      (typeof obj[key] === "number" && obj[key] % 1 === 0)
    ) {
      result[key] = obj[key];
    } else if (obj[key] === null) {
      result[key] = "-";
    } else {
      result[key] = {};
      for (const k in obj[key]) {
        if (typeof obj[key][k] === "number") {
          result[key][k] = obj[key][k].toFixed(4);
        } else {
          result[key][k] = obj[key][k];
        }
      }
    }
  }
  return result;
};

export const computeScreeners = async (newArr) => {
  const resp = await axios
    .get(`${config.apiUrl}/compute-screeners`, {
      params: {
        filters: newArr,
        days: 50,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => console.log("[Compute Screener error: ]", err));
  return resp.data;
};