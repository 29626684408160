import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard';
import { ChakraProvider } from "@chakra-ui/react";
import { useState } from 'react';

function App() {
  const [isDark, setIsDark] = useState(true)
  return (
    <ChakraProvider>
      <div className={`App min-h-screen ${isDark?'dark':''}`}>
        <Dashboard isDark={isDark} setIsDark={setIsDark} />
      </div>
    </ChakraProvider>
  );
}

export default App;
