// const bgColor = useColorModeValue("white", "#1C232B"); //React-Select does not recognize "brand.blackSecondary"
// const textColor = useColorModeValue("black", "white");
const bgColorDark = "#1C232B"; //React-Select does not recognize "brand.blackSecondary"
const bgColor = "white"; //React-Select does not recognize "brand.blackSecondary"
const textColor = "black";
const textColorDark = "white";

const customSelectStyles = (isDark) => ({
  control: (base) => ({
    ...base,
    width: 120,
    fontWeight: "bold",
    backgroundColor: isDark ? bgColorDark : bgColor,
    textColor: textColor,
    color: isDark ? textColorDark : textColor,
    border: 0,
    boxShadow: "none",
  }),
  option: (base) => ({
    ...base,
    fontWeight: "semibold",
    fontSize: "14px",
    backgroundColor: isDark ? bgColorDark : bgColor,
    color: isDark ? textColorDark : textColor,
    padding: "2px",
  }),
  menu: (base) => ({
    ...base,
    width: 600,
    backgroundColor: isDark ? bgColorDark : bgColor,
    textColor: isDark ? textColorDark : textColor,
  }),
  singleValue: (base) => ({
    ...base,
    color: isDark?textColorDark:textColor,
  }),
  input: (base) => ({
    ...base,
    color: isDark?textColorDark:textColor,
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "2px",
      marginRight: "2",
    },

    "::-webkit-scrollbar-track": {
      width: "2px",
      background: "#435055",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#C4C4C4",
      borderRadius: "10px",
    },

    marginRight: ".5rem",
    marginTop: ".5rem",
    marginBottom: ".5rem",
  }),
});

export default customSelectStyles;
