import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Skeleton } from "@chakra-ui/react";
import { findIndex } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { BsSearch, BsStar, BsStarFill } from "react-icons/bs";
import Async, { components } from "react-select";
import { getStockMoreInfo } from "../../api";
import { fixNumber } from "../../utils/price-parser";
import MoreInfoModal from "../MoreInfoModal";
import WatchlistModal from "../StockAssets/WatchlistModal";
import customSelectStyles from "./select-styles";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex justify-between px-2">
        <p>{props.data.label}</p>
        <p className="truncate text-ellipsis px-2">
          {props.data.name.toUpperCase()}
        </p>
      </div>
    </components.Option>
  );
};

const StockInfo = (
  {
    selectedStock,
    setSelectedStock,
    allStocks,
    isDark,
    activeGroup,
    setActiveGroup,
    groupList,
    setGroupList,
    isLoaded
  },
  ref
) => {
  const [stockOptions, setStockOptions] = useState([]);
  const [selectedStockObject, setSelectedStockObject] = useState();
  const [stockInfo, setStockInfo] = useState(selectedStock);
  const [moreInfo, setMoreInfo] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isMoreOpen, onOpen: onMoreOpen, onClose: onMoreClose } = useDisclosure();
  const [loaded, setLoaded] = useState(false);
  const [trendColor, setTrendColor] = useState(
    selectedStock.price_change > 0
      ? "green-primary"
      : selectedStock.price_change < 0
        ? "red-primary"
        : "white"
  );
  const checkTrendColor = (price_change) => {
    setTrendColor(
      price_change > 0
        ? "green-primary"
        : price_change < 0
          ? "red-primary"
          : "white"
    );
  };

  const isStarred = () => {
    const index = activeGroup?.stocks?.indexOf(stockInfo._id);
    if (index !== -1) {
      return true;
    }
    return false;
  };

  const showMoreInfo = async () => {
    const result = await getStockMoreInfo(stockInfo.symbol);
    setMoreInfo(result);
    onMoreOpen();
  };

  useEffect(() => {
    checkTrendColor(stockInfo.price_change);
  }, [stockInfo]);

  useImperativeHandle(ref, () => ({
    handleNewTransaction: (newTransaction) => {
      if (newTransaction !== null) {
        if (stockInfo.symbol === newTransaction.symbol) {
          setStockInfo({
            ...stockInfo,
            curr_price: newTransaction.price,
            price_change: newTransaction.price_change,
            trades: newTransaction.trades,
            market_cap: newTransaction.value,
          });
        }
      }
    },
  }));
  const handleSelectChange = (event) => {
    let a = allStocks.find((stock) => stock.symbol === event.value);
    setSelectedStockObject({
      label: a.symbol,
      value: a.symbol,
      name: a.name,
    });
    const index = findIndex(allStocks, {
      symbol: a.symbol,
    });

    setSelectedStock(allStocks[index]);
  };

  useEffect(() => {
    setLoaded(false);
    setStockInfo(selectedStock);
    setLoaded(true);
  }, [selectedStock]);

  useEffect(() => {
    let stocksList = [];
    for (let i = 0; i < allStocks.length; i++) {
      stocksList.push({
        label: allStocks[i].symbol,
        value: allStocks[i].symbol,
        name: allStocks[i].full_name,
      });
    }
    setStockOptions(stocksList);
    setSelectedStockObject({
      label: selectedStock.symbol,
      value: selectedStock.name,
    });
  }, [selectedStock, allStocks]);

  return (
    <div className="w-full h-full bg-light-container-bg dark:bg-container-bg relative text-dark-bg dark:text-white text-center flex flex-row items-center justify-between px-2">
      <div className="flex items-center">
        <MoreInfoModal
          isOpen={isMoreOpen}
          onClose={onMoreClose}
          symbol={stockInfo.symbol}
          moreInfo={moreInfo}
        />
        <WatchlistModal
          stockInfo={stockInfo}
          isOpen={isOpen}
          onClose={onClose}
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          groupList={groupList}
          setGroupList={setGroupList}
        />
        <Async
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          options={stockOptions}
          styles={customSelectStyles(isDark)}
          value={selectedStockObject}
          onChange={handleSelectChange}
          backspaceRemovesValue
        />
        <BsSearch />
        <div className="ml-5">
          {isStarred() ? (
            <BsStarFill
              className="hover:cursor-pointer"
              onClick={onOpen}
              color={"#E9C920"}
            />
          ) : (
            <BsStar
              className="hover:cursor-pointer"
              onClick={onOpen}
              color={"#3F4A54"}
            />
          )}
        </div>
        <div className="ml-5">
          <p className={`font-extrabold text-xl text-${trendColor}`}>
            {parseFloat(stockInfo.curr_price || 0).toFixed(4) || "-"}
          </p>
        </div>
        <div className="ml-5">
          <p className={`text-tiny text-${trendColor}`}>
            {stockInfo.prev_close
              ? Math.abs(stockInfo.curr_price - stockInfo.prev_close).toFixed(4)
              : "-" || "-"}
          </p>
          <p className={`text-tiny text-${trendColor}`}>{`(${parseFloat(stockInfo.price_change || 0).toFixed(4) || "-"
            }%)`}</p>
        </div>
      </div>
      <div className="hidden md:flex xl:flex">
        <div className="flex text-xs">
          <div className="flex-col mx-5">
            <p>Open</p>
            <p>Low</p>
          </div>
          <div className="flex-col mx-5">
            <Skeleton isLoaded={loaded}><p className="font-extrabold">{stockInfo.open_price || "-"}</p></Skeleton>
            <Skeleton isLoaded={loaded}> <p className="font-extrabold text-red-primary">
              {stockInfo.low_price || "-"}
            </p> </Skeleton>

          </div>
        </div>
        <div className="flex text-xs">
          <div className="flex-col mx-5">
            <p>High</p>
            <p>Volume</p>
          </div>
          <div className="flex-col mx-5">
          <Skeleton isLoaded={loaded}>
            <p className="font-extrabold text-green-primary">
              {stockInfo.high_price || "-"}
            </p>
          </Skeleton>
          <Skeleton isLoaded={loaded}>
            <p className="font-extrabold">
              {fixNumber(stockInfo.volume || "-")}
            </p>
          </Skeleton>
          </div>
        </div>
        <div className="flex text-xs">
          <div className="flex-col mx-5">
            <p>Mkt Cap</p>
            <p>Trades</p>
          </div>
          <div className="flex-col mx-5">
            <Skeleton isLoaded={loaded}>
            <p className="font-extrabold">
              {fixNumber(stockInfo.market_cap || "-")}
            </p>
            </Skeleton>
            <Skeleton isLoaded={loaded}>
            <p className="font-extrabold">
              {fixNumber(stockInfo.trades || "-")}
            </p>
            </Skeleton>
          </div>
        </div>
        <div className="border-2 hidden 2xl:flex"></div>
        <div className="text-xs hidden  2xl:flex">
          <div className="flex-col mx-5">
            <p>52wk L</p>
            <p>52wk H</p>
          </div>
          <div className="flex-col mx-5">
          <Skeleton isLoaded={loaded}>
            <p className="font-extrabold">
              {fixNumber(stockInfo.fiftytwo_week_low || "-")}
            </p>
            </Skeleton>
            <Skeleton isLoaded={loaded}>
            <p className="font-extrabold">
              {fixNumber(stockInfo.fiftytwo_week_high || "-")}
            </p>
            </Skeleton>
          </div>
        </div>
        <div className="text-xs hidden 2xl:flex">
          <div className="flex-col mx-5">
            <p>Value</p>
          </div>
          <div className="flex-col mx-5">
          <Skeleton isLoaded={loaded}>
            <p className="font-extrabold">
              {fixNumber(stockInfo.trades || "-")}
            </p>
          </Skeleton>
          </div>
        </div>
        <div
          onClick={showMoreInfo}
          className="w-25 h-8 p-2 hover:cursor-pointer text-xs font-bold text-dark-bg dark:text-white bg-light-bg dark:bg-black flex justify-center items-center rounded-lg"
        >
          <p>More info</p>
        </div>
        <div className="w-30 h-full "></div>
      </div>
    </div>
  );
};

export default forwardRef(StockInfo);
