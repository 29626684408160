import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    useDisclosure
} from "@chakra-ui/react";
import { BsTrashFill } from "react-icons/bs"
import React, { Fragment, useEffect, useState } from "react";
import { updateWatchlist, getWatchList } from "../../api";
import { cloneDeep, findIndex } from "lodash";
import { DeleteWatchlistGroupAlertDialog } from "../DeleteWatchlistGroupAlertDialog";


const EditWatchlistNameModal = ({ isOpen, onClose, activeGroup, setGroupList, groupList, setActiveGroup }) => {
    const [newName, setNewName] = useState("");
    const toast = useToast();
    const { isOpen: isDeleteDialogOpen, onOpen: onDeleteDialogOpen, onClose: onDeleteDialogClose } = useDisclosure();

    useEffect(() => {
        setNewName(activeGroup.name);
    }, [activeGroup]);

    const handleEditWatchlistName = async () => {
        if (newName !== "" && newName !== activeGroup.name) {
            const watchlistPayload = { ...activeGroup, newName, email: "mytrade_test@gmail.com" };
            await updateWatchlist(watchlistPayload).then(async (resp) => {
                if (resp.status === 'success') {
                    const updatedGroupList = cloneDeep(groupList);
                    const updatedWatchlist = cloneDeep(activeGroup);
                    updatedWatchlist.name = newName;

                    const index = findIndex(updatedGroupList, { _id: activeGroup._id });
                    updatedGroupList[index] = updatedWatchlist;

                    setGroupList(updatedGroupList)
                    setActiveGroup(updatedGroupList[index]);

                    setNewName("");
                    onClose();
                }

                toast({
                    title: 'Edit Watchlist Name',
                    description: resp.message,
                    status: resp.status,
                    duration: 3000,
                    isClosable: true,
                }); 
            });
        }
    };

    const onDeleteSuccess = () => {
        const newGroups = cloneDeep(groupList).filter(wg => wg._id !== activeGroup._id);
        setGroupList(newGroups)
        setActiveGroup(newGroups[0]);

        onClose();
    }
    return (
        <Fragment>
        <DeleteWatchlistGroupAlertDialog isOpen={isDeleteDialogOpen} onClose={onDeleteDialogClose} activeGroup={activeGroup} onSuccess={onDeleteSuccess} />
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="flex items-baseline">
                    <p className="text-sm font-bold pr-1">{activeGroup.name} - Edit Watchlist Name</p>
                </ModalHeader>
                <ModalBody>
                    <div className="flex flex-col justify-around h-[160px] py-5">
                        <input value={newName} onChange={(e) => { setNewName(e.target.value) }} placeholder="Group name" className="h-[45px] p-3" />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="flex justify-between items-center w-full">
                        <BsTrashFill onClick={onDeleteDialogOpen} className="hover:cursor-pointer text-red-800">Delete Watchlist Group</BsTrashFill>
                        <button onClick={handleEditWatchlistName}>Save New Name</button>
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </Fragment>
    );
};

export default EditWatchlistNameModal;
