import { CircularProgress } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getBidAndAsk } from "../../api";

const BidAndAsk = ({ symbol }) => {
  const [bidAsk, setBidAsk] = useState([]);
  const [sums, setSums] = useState([0, 0]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const resp = await getBidAndAsk(symbol);
      let bqSum = 0;
      let aqSum = 0;
      resp.data.map((ba) => {
        bqSum += +ba.bidqty;
        aqSum += +ba.askqty;
      });
      setSums([bqSum, aqSum]);
      setBidAsk(resp.data);
      setLoading(false)
    })();
  }, [symbol]);
  const [bidSum, askSum] = sums;
  return (
    <div>
      {loading ? <div className="flex justify-center items-center pt-10 text-dark-bg dark:text-white"> <CircularProgress isIndeterminate color='green.400' /> </div> : 
      bidAsk.map((ba, index) => (
        <div key={index} className="flex justify-between items-center h-[30px] text-dark-bg dark:text-white">
          <div className="w-full relative h-full flex items-center bg-light-bg dark:bg-[rgba(0,100,0,0.1)] text-left pl-2 text-xs">
            <div
              style={{ minWidth: (ba.bidqty / bidSum) * 100 }}
              className={`absolute h-full bg-green-primary dark:bg-[rgba(0,100,0,0.5)] right-0 z-0`}
            >
              &nbsp;
            </div>
            <div className="flex justify-between w-full z-10 px-2">
              <p className="font-bold ">{ba.bidprice}</p>
              <p>{ba.bidqty}</p>
            </div>
          </div>
          <div className="w-full relative h-full flex items-center bg-[rgba(100,0,0,0.1)] text-right pr-2 text-xs">
            <div
              style={{ minWidth: (ba.askqty / askSum) * 100 }}
              className={`absolute h-full bg-red-primary dark:bg-[rgba(100,0,0,0.5)] l-0 z-0`}
            >
              &nbsp;
            </div>
            <div className="flex flex-row-reverse justify-between w-full z-10 px-2">
              <p className="font-bold ">{ba.askprice}</p>
              <p>{ba.askqty}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BidAndAsk;
