import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { createWatchlistGroup, getWatchList } from "../../api";

const priorities = {
  "High Priority": {
    label: "High Priority",
    value: "High Priority",
    color: "#F85E5E",
    hoverColor: "#FBB1B1",
    order: 1,
  },
  "Slight Priority": {
    label: "Slight Priority",
    value: "Slight Priority",
    color: "#E07714",
    hoverColor: "#FBC18B",
    order: 2,
  },
  "Mid Priority": {
    label: "Mid Priority",
    value: "Mid Priority",
    color: "#E9C920",
    hoverColor: "#FFF4BA",
    order: 3,
  },
  "Fair Priority": {
    label: "Fair Priority",
    value: "Fair Priority",
    color: "#04A76B",
    hoverColor: "#B5F8DF",
    order: 4,
  },
  "Low Priority": {
    label: "Low Priority",
    value: "Low Priority",
    color: "#0480A7",
    hoverColor: "#91E5FF",
    order: 5,
  },
};
const AddWatchlistGroupModal = ({ isOpen, onClose, setGroupList, groupList }) => {
  const [newGroup, setNewGroup] = useState(null);
  const [newName, setNewName] = useState("");
  const [newPriority, setNewPriority] = useState("High Priority");
  const toast = useToast();

  useEffect(() => {
    if (newPriority !== "") {
      setNewGroup({
        name: newName,
        stocks: [],
        priority: newPriority,
        color: priorities[newPriority].color,
        hoverColor: priorities[newPriority].hoverColor,
        order: priorities[newPriority].order,
        email: "mytrade_test@gmail.com",
        watchlistDetails: {
          name: newName,
          stocks: [],
          priority: newPriority,
          color: priorities[newPriority].color,
          hoverColor: priorities[newPriority].hoverColor,
          order: priorities[newPriority].order,
        },
      });
    }
  }, [newName, newPriority]);
  const handleAddNewGroup = async () => {
    if (newName !== "" && newPriority !== "") {
      await createWatchlistGroup(newGroup).then(async (resp) => {
        if (resp.status === 'success') {
          const newGroupList = groupList;
          newGroupList.push(resp.resp.data)
          setGroupList(newGroupList);
          setNewName("");
          setNewPriority("High Priority")
          onClose();
        }

        toast({
          title: 'Add new watchlist group',
          description: resp.message,
          status: resp.status,
          duration: 3000,
          isClosable: true,
      });
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="flex items-baseline">
          <p className="text-sm font-bold pr-1">Add new Watchlist Group</p>
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col justify-around h-[160px] py-5">
            <input value={newName} onChange={(e)=>{setNewName(e.target.value)}} placeholder="Group name" className="h-[45px] p-3" />
            <select value={newPriority} onChange={(e)=>{setNewPriority(e.target.value)}} className="h-[45px] p-3">
              <option value={"High Priority"}>High Priority</option>
              <option value={"Slight Priority"}>Slight Priority</option>
              <option value={"Mid Priority"}>Mid Priority</option>
              <option value={"Fair Priority"}>Fair Priority</option>
              <option value={"Low Priority"}>Low Priority</option>
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={handleAddNewGroup}>Add new group</button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddWatchlistGroupModal;
