import React from "react";
import { BsArrowUpCircle, BsArrowDownCircle } from "react-icons/bs";
import { MdRemoveCircleOutline } from "react-icons/md";

const Ticker = ({ symbol, value, price_change, price }) => {
  const primaryColor =
    price_change > 0
      ? "green-primary"
      : price_change < 0
      ? "red-primary"
      : "bg-dark";
  const primaryColorDark =
    price_change > 0
      ? "green-primary"
      : price_change < 0
      ? "red-primary"
      : "white";
  return (
    <div
      style={{ width: 108 }}
      className={`flex bg-white dark:bg-dark-bg flex-col justify-center items-center border border-${primaryColor} dark:border-${primaryColorDark}`}
    >
      <div className={`flex justify-evenly font-bold items-center text-${primaryColor} text-sm  w-full`}>
        <p>{symbol}</p>
        <p>{price}</p>
      </div>
      <div className="flex text-lg items-center justify-center w-full">
        <p className={`px-1 text-sm text-${primaryColor}`}>{value}</p>
        {price_change > 0 ? (
          <BsArrowUpCircle size={"12"} className="text-green-primary" />
        ) : price_change < 0 ? (
          <BsArrowDownCircle size={"12"} className="text-red-primary" />
        ) : (
          <MdRemoveCircleOutline className="text-white" />
        )}
      </div>
    </div>
  );
};

export default Ticker;
