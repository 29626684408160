export const fixNumber = (number) => {
  if (number === null) {
    return null;
  }
  let a = parseFloat(number);
  if (a >= 100000000) {
    return (a / 100000000).toFixed(2) + "B";
  } else if (a >= 1000000) {
    return (a / 1000000).toFixed(2) + "M";
  } else if (a >= 1000) {
    return (a / 1000).toFixed(2) + "K";
  }
  return a.toFixed(2);
};
