import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormLabel,
    Select,
    CircularProgress,
    Flex
} from '@chakra-ui/react'
import { useState } from 'react';
import { computeScreeners } from "../../api/index"

import { screenerList } from './screenerList';
import { ScreenerTable } from './ScreenerTable';
export const ScreenerModal = (props) => {
    const { isOpen, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [screenerValues, setScreenerValues] = useState([]);
    const handleValues = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const values = e.currentTarget.elements
        const filters = screenerList.map((screener) => {
            return {
                val: values[screener.label].value,
                label: screener.label
            };
        })
        let data = await computeScreeners(filters);
        setScreenerValues(data);
        setIsLoading(false);
    }

    const clearTable = () => {
        setScreenerValues([]);
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={screenerValues.length > 0 ? "6xl" : "md"} h="60vh">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Screener</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {screenerValues.length > 0 ? <ScreenerTable stocks={screenerValues} clearTable={clearTable} /> :
                            isLoading ? <Flex align="center" justify="center" mt="2"><CircularProgress isIndeterminate /></Flex> :
                                <Flex direction="column" align="center" justify="center">
                                    <form onSubmit={handleValues}>
                                        {screenerList.map((screener, index) => {
                                            return (
                                                <>
                                                    <FormLabel key={index}>{screener.label}</FormLabel>
                                                    <Select id={screener.label}>
                                                        {screener.choices.map((choice) => {
                                                            return (
                                                                <option value={choice.value}>
                                                                    {choice.label}
                                                                </option>
                                                            )
                                                        })}
                                                    </Select>
                                                </>
                                            )
                                        })}

                                        <Flex align="center" justify="center" mt="2">
                                            <Button type="submit">
                                                Run
                                            </Button>
                                        </Flex>

                                    </form>
                                </Flex>
                        }

                    </ModalBody>

                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal></>
    )
}