import { Box, Tooltip } from "@chakra-ui/react";
import { findIndex } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { fixNumber } from "../../utils/price-parser";
import "./index.css";

const StockAsset = (
  { stock, handleClick, handleAddWatchlist, handleRemoveFromWatchlist, activeGroup },
  ref
) => {
  const [stockAssetDetails, setStockAssetDetails] = useState({
    symbol: stock?.symbol,
    market_cap: stock.market_cap,
    price_change: stock.price_change,
    curr_price: stock.curr_price,
    trades: stock.trades,
    open_price: stock.open_price,
    low_price: stock.low_price,
    high_price: stock.high_price,
  });
  //   console.log(stock, isWatchListed)

  const checkPriceChange = (price_change) => {
    setTrendColor(
      price_change > 0
        ? "green-primary"
        : price_change < 0
        ? "red-primary"
        : "dark-bg"
    );setTrendColorDark(
      price_change > 0
        ? "green-primary"
        : price_change < 0
        ? "red-primary"
        : "white"
    );
  };
  const [trendColorDark, setTrendColorDark] = useState(
    stockAssetDetails.price_change > 0
      ? "green-primary"
      : stockAssetDetails.price_change < 0
      ? "red-primary"
      : "white"
  );
  const [trendColor, setTrendColor] = useState(
    stockAssetDetails.price_change > 0
      ? "green-primary"
      : stockAssetDetails.price_change < 0
      ? "red-primary"
      : "dark-bg"
  );

  // useEffect(()=>{
  //   isStarred();
  // }, [activeGroup])
  useEffect(() => {
    setStockAssetDetails(stock);
    checkPriceChange(stock.price_change);
  }, [stock]);

  const isStarred = () => {
    const index = activeGroup?.stocks?.indexOf(stock._id);
    if (index !== -1) {
      return true;
    }
    return false;
  };

  useImperativeHandle(ref, () => ({
    updateStockAsset: (data) => {
      checkPriceChange(data.price_change);
      setStockAssetDetails({
        ...data,
        symbol: data.symbol,
        curr_price: data.price,
        price_change: data.price_change,
        market_cap: data.value,
        trades: data.trades,
      });
    },
  }));
  return (
    <tr
      className={`stock-container hover:bg-light-bg dark:hover:bg-black hover:cursor-pointer px-2 group`}
      onClick={() => {
        handleClick(stockAssetDetails);
      }}
    >
      <td
        className={`px-2 ${
          !isStarred() ? "invisible" : ""
        } group-hover:visible`}
        onClick={(e) => {
          e.stopPropagation();
          if (isStarred()) {
            handleRemoveFromWatchlist(stockAssetDetails);
          } else {
            handleAddWatchlist(stockAssetDetails);
          }
        }}
      >
        {isStarred() ? (
          <Tooltip label="Remove from Watchlist">
            <Box>
              <BsStarFill className={"text-yellow-primary text-md"} />
            </Box>
          </Tooltip>
        ) : (
          <>
            <Tooltip label="Add to Watchlist">
              <Box>
                <BsStarFill className={"text-gray-star text-md"} />
              </Box>
            </Tooltip>
          </>
        )}
      </td>
      <td className={`font-bold w-full text-${trendColor} dark:text-${trendColorDark}`}>
        {stockAssetDetails.symbol}
      </td>
      <td className={` w-full text-${trendColor} dark:text-${trendColorDark}`}>
        {stockAssetDetails.curr_price}
      </td>
      <td className={` w-full text-${trendColor} dark:text-${trendColorDark}`}>
        {parseFloat(stockAssetDetails.price_change).toFixed(2)}
      </td>
      <td className={` w-full text-${trendColor} dark:text-${trendColorDark}`}>
        {fixNumber(stockAssetDetails.market_cap)}
      </td>
      <td className={` w-full text-${trendColor} dark:text-${trendColorDark}`}>
        {fixNumber(stockAssetDetails.trades)}
      </td>
    </tr>
  );
};

export default forwardRef(StockAsset);
