import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Button,
    Flex,
    Spacer
} from '@chakra-ui/react'

import {DeleteIcon} from "@chakra-ui/icons"
import { getValidNumberValue } from '../../../utils/valid-number';
const headers = ["Symbol","52 Week High", "Year to Date %",
    "Month to Date %", "Week to Date %", "Volume Spike", "Volume Average", "Value Average"]

export const ScreenerTable = (props) => {
    const { stocks,clearTable } = props;
    return (
        <Box h="60vh" overflowY="scroll">
        <TableContainer>
            <Table variant='simple' size="sm">
                <TableCaption placement="top">
                    <Flex >
                    Screener Results: {stocks.length} Stocks
                    <Spacer/>
                    <Button colorScheme='red' rightIcon={<DeleteIcon/>} onClick={clearTable}>
                        Clear Table
                    </Button>
                    </Flex>
                    
                </TableCaption>
                <Thead>
                    <Tr>
                        {headers.map(header => <Th>{header}</Th>)}
                    </Tr>
                </Thead>
                <Tbody>
                    {stocks.map((stock) => {
                        const fiftytwo_week_high = getValidNumberValue(stock,'fiftytwo_week_high');
                        const year_to_date = getValidNumberValue(stock, 'year_to_date');
                        const month_to_date = getValidNumberValue(stock, "month_to_date");
                        const week_to_date = getValidNumberValue(stock,"week_to_date");
                        const volume_spike = getValidNumberValue(stock,"volume_spike");
                        const volume_average = getValidNumberValue(stock,"volume_avg");
                        const value_average = getValidNumberValue(stock,"value_avg");

                        return (
                        <Tr>
                            <Td>{stock.symbol}</Td>
                            <Td>{fiftytwo_week_high}</Td>
                            <Td>{year_to_date}</Td>
                            <Td>{month_to_date}</Td>
                            <Td>{week_to_date}</Td>
                            <Td>{volume_spike}</Td>
                            <Td>{volume_average}</Td>
                            <Td>{value_average}</Td>
                         </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
        </Box>
    )
}