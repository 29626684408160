export const screenerList = [
    {
      screener_id: 1,
      label: "52 Week High",
      val: "Any",
      value: { value: "Any", label: "Any" },
      choices: [
        { value: "Any", label: "Any" },
        { value: "Is 52 week high", label: "Is 52 week high" },
        { value: "1% or less below high", label: "1% or less below high" },
        { value: "5% or less below high", label: "5% or less below high" },
        { value: "10% or less below high", label: "10% or less below high" },
        { value: "20% or less below high", label: "20% or less below high" },
        { value: "30% or less below high", label: "30% or less below high" },
        { value: "40% or less below high", label: "40% or less below high" },
        { value: "60% or less below high", label: "50% or less below high" },
        { value: "70% or less below high", label: "70% or less below high" },
        { value: "80% or less below high", label: "80% or less below high" },
        { value: "90% or less below high", label: "90% or less below high" },
        { value: "95% or less below high", label: "95% or less below high" },
        { value: "99% or less below high", label: "99% or less below high" },
      ],
    },
    {
      screener_id: 2,
      label: "Year to Date %",
      val: "Any",
      value: { value: "Any", label: "Any" },
      choices: [
        { value: "Any", label: "Any" },
        { value: "Up", label: "Up" },
        { value: "Down", label: "Down" },
        { value: "5% to 10%", label: "5% to 10%" },
        { value: "10% to 15%", label: "10% to 15%" },
        { value: "15% to 20%", label: "15% to 20%" },
        { value: "20% to 30%", label: "20% to 30%" },
        { value: "30% to 50%", label: "30% to 50%" },
        { value: "50% to 100%", label: "50% to 100%" },
        { value: "100% or more", label: "100% or more" },
        { value: "-5% to -10%", label: "-5% to -10%" },
        { value: "-10% to -15%", label: "-10% to -15%" },
        { value: "-15% to -20%", label: "-15% to -20%" },
        { value: "-20% to -30%", label: "-20% to -30%" },
        { value: "-30% to -50%", label: "-30% to -50%" },
        { value: "-50% to -100%", label: "-50% to -100%" },
        { value: "-100% or more", label: "-100% or more" },
        { value: "No Change", label: "No Change" },
        { value: "5% or more", label: "5% or more" },
        { value: "10% or more", label: "10% or more" },
      ],
    },
    {
      screener_id: 3,
      label: "Month to Date %",
      val: "Any",
      value: { value: "Any", label: "Any" },
      choices: [
        { value: "Any", label: "Any" },
        { value: "Up", label: "Up" },
        { value: "Down", label: "Down" },
        { value: "5% to 10%", label: "5% to 10%" },
        { value: "10% to 15%", label: "10% to 15%" },
        { value: "15% to 20%", label: "15% to 20%" },
        { value: "20% to 30%", label: "20% to 30%" },
        { value: "30% to 50%", label: "30% to 50%" },
        { value: "50% to 100%", label: "50% to 100%" },
        { value: "100% or more", label: "100% or more" },
        { value: "-5% to -10%", label: "-5% to -10%" },
        { value: "-10% to -15%", label: "-10% to -15%" },
        { value: "-15% to -20%", label: "-15% to -20%" },
        { value: "-20% to -30%", label: "-20% to -30%" },
        { value: "-30% to -50%", label: "-30% to -50%" },
        { value: "-50% to -100%", label: "-50% to -100%" },
        { value: "-100% or more", label: "-100% or more" },
        { value: "No Change", label: "No Change" },
        { value: "5% or more", label: "5% or more" },
        { value: "10% or more", label: "10% or more" },
      ],
    },
    {
      screener_id: 4,
      label: "Week to Date %",
      val: "Any",
      value: { value: "Any", label: "Any" },
      choices: [
        { value: "Any", label: "Any" },
        { value: "Up", label: "Up" },
        { value: "Down", label: "Down" },
        { value: "5% to 10%", label: "5% to 10%" },
        { value: "10% to 15%", label: "10% to 15%" },
        { value: "15% to 20%", label: "15% to 20%" },
        { value: "20% to 30%", label: "20% to 30%" },
        { value: "30% to 50%", label: "30% to 50%" },
        { value: "50% to 100%", label: "50% to 100%" },
        { value: "100% or more", label: "100% or more" },
        { value: "-5% to -10%", label: "-5% to -10%" },
        { value: "-10% to -15%", label: "-10% to -15%" },
        { value: "-15% to -20%", label: "-15% to -20%" },
        { value: "-20% to -30%", label: "-20% to -30%" },
        { value: "-30% to -50%", label: "-30% to -50%" },
        { value: "-50% to -100%", label: "-50% to -100%" },
        { value: "-100% or more", label: "-100% or more" },
        { value: "No Change", label: "No Change" },
        { value: "5% or more", label: "5% or more" },
        { value: "10% or more", label: "10% or more" },
      ],
    },
    // {
    //   screener_id: 5,
    //   label: "Net Foreign", //1, 5, 15, 30, 60, 360
    //   days: "1",
    //   value: { value: "Any", label: "Any" },
    //   type: "main",
    //   choices: [
    //     { value: "Any", label: "Any" },
    //     { value: "Buying", label: "Buying" },
    //     { value: "Selling", label: "Selling" },
    //     { value: "Neutral", label: "Neutral" },
    //     { value: "Over 1M NFB", label: "Over 1M NFB" },
    //     { value: "Over 5M NFB", label: "Over 5M NFB" },
    //     { value: "Over 10M NFB", label: "Over 10M NFB" },
    //     { value: "Over 20M NFB", label: "Over 20M NFB" },
    //     { value: "Over 50M NFB", label: "Over 50M NFB" },
    //     { value: "Over 100M NFB", label: "Over 100M NFB" },
    //     { value: "Over 1M NFS", label: "Over 1M NFS" },
    //     { value: "Over 5M NFS", label: "Over 5M NFS" },
    //     { value: "Over 10M NFS", label: "Over 10M NFS" },
    //     { value: "Over 20M NFS", label: "Over 20M NFS" },
    //     { value: "Over 50M NFS", label: "Over 50M NFS" },
    //     { value: "Over 100M NFS", label: "Over 100M NFS" },
    //   ],
    // },
    {
      screener_id: 6,
      label: "Volume Spike",
      val: "Any",
      days: "1",
      value: { value: "Any", label: "Any" },
      type: "main",
      choices: [
        { value: "Any", label: "Any" },
        { value: "10-day volume breakout", label: "10-day volume breakout" },
        { value: "20-day volume breakout", label: "20-day volume breakout" },
        { value: "50-day volume breakout", label: "50-day volume breakout" },
        { value: "100-day volume breakout", label: "100-day volume breakout" },
        { value: "200-day volume breakout", label: "200-day volume breakout" },
      ],
    },
    {
      screener_id: 7,
      label: "Volume Average", //10, 15, 20
      val: "Any",
      days: "1",
      value: { value: "Any", label: "Any" },
      type: "main",
      choices: [
        { value: "Any", label: "Any" },
        {
          value: "Current volume is greater than or equal the volume average",
          label: "Current volume is greater than or equal the volume average",
        },
        {
          value: "Current volume is less than the volume average",
          label: "Current volume is less than the volume average",
        },
      ],
    },
    // {    //Removed Volume Surge as per advice
    //      "screener_id": 8,
    //     "label": "Volume Surge", //10, 15, 20, 50
    //     "days": "1",
    //     "value": { "value": "Any", "label": "Any" },
    //     "type": "main",
    //     "choices": [
    //          { "value": "Any", "label": "Any" },
    //          { "value": "100% higher than the average volume", "label": "100% higher than the average volume" },
    //          { "value": "200% higher than the average volume", "label": "200% higher than the average volume" },
    //          { "value": "300% higher than the average volume", "label": "300% higher than the average volume" },
    //          { "value": "400% higher than the average volume", "label": "400% higher than the average volume" },
    //     ]
    // },
    {
      screener_id: 9,
      label: "Value Average", //10, 15, 20
      days: "1",
      val: "Any",
      value: { value: "Any", label: "Any" },
      type: "main",
      choices: [
        { value: "Any", label: "Any" },
        { value: "Value Average >= 1M", label: "Value Average >= 1M" },
        { value: "Value Average >= 5M", label: "Value Average >= 5M" },
        { value: "Value Average >= 10M", label: "Value Average >= 10M" },
        { value: "Value Average >= 20M", label: "Value Average >= 20M" },
        { value: "Value Average >= 50M", label: "Value Average >= 50M" },
        { value: "Value Average >= 100M", label: "Value Average >= 100M" },
        { value: "Value Average < 1M", label: "Value Average < 1M" },
        { value: "Value Average < 5M", label: "Value Average < 5M" },
        { value: "Value Average < 10M", label: "Value Average < 10M" },
        { value: "Value Average < 20M", label: "Value Average < 20M" },
        { value: "Value Average < 50M", label: "Value Average < 50M" },
        { value: "Value Average < 100M", label: "Value Average < 100M" },
      ],
    },
  ];
  