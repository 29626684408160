import {
  CircularProgress,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box
} from "@chakra-ui/react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { getTransactions } from "../../api";
import { fixNumber } from "../../utils/price-parser";

const Transactions = ({ selectedStock }, ref) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  useImperativeHandle(ref, () => ({
    handleNewTransaction: (newTransaction) => {
      if (newTransaction !== null) {
        if (selectedStock.symbol === newTransaction.symbol) {
          let a = {
            price: newTransaction.price,
            price_change: newTransaction.price_change,
            symbol: newTransaction.symbol,
            time_server: newTransaction.time_server,
            transaction_number: newTransaction.transaction_number,
            value: newTransaction.curr_price * newTransaction.trades,
            volume: newTransaction.trades,
          };
          setTransactions([a, ...transactions]);
        }
      }
    },
  }));

  useEffect(() => {
    (async () => {
      setLoading(true)
      const t = await getTransactions({ symbols: [selectedStock.symbol] });
      setTransactions(t);
      setLoading(false)
    })();
  }, [selectedStock]);
  return (
    <div
      style={{ height: window.innerHeight / 2.20 }}
      className="flex flex-col w-full overflow-auto"
    >

      {loading ?
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"full"}>
          <CircularProgress isIndeterminate color="green.400" />
        </Box> :
        <Table className="w-full text-sm" size="sm" variant="unstyled">
          <Thead className="text-tiny text-dark-bg dark:text-white uppercase top-0 sticky bg-light-bg dark:bg-gray-700">
            <Tr>
              <Th>Time</Th>
              <Th>Price</Th>
              <Th>Volume</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody className="">
            {
              transactions.map((t, index) => {
                const time = new Date(t.time_server);
                let data_time =
                  time.getHours() +
                  ":" +
                  (time.getMinutes() < 10
                    ? `0${time.getMinutes()}`
                    : time.getMinutes());
                const colorDark =
                  t.price_change > 0
                    ? "green-primary"
                    : t.price_change < 0
                      ? "red-primary"
                      : "white";
                const color =
                  t.price_change > 0
                    ? "green-primary"
                    : t.price_change < 0
                      ? "red-primary"
                      : "dark-bg";
                return (
                  <Tr key={index} className={`text-xs h-[30px] text-${color} dark:text-${colorDark}`}>
                    <Td>{data_time}</Td>
                    <Td>{fixNumber(t.price)}</Td>
                    <Td>{fixNumber(t.volume)}</Td>
                    <Td>{fixNumber(t.value)}</Td>
                  </Tr>
                );
              })}
          </Tbody>

        </Table>
      }
    </div>
  );
};

export default forwardRef(Transactions);
