import axios from "axios";

// // Make requests to CryptoCompare API
// export async function makeApiRequest(path) {
//   try {
//     const response = await axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/${path}`)
//       .then((res) => {
//         res.data?.status == 400
//           ? ""
//           : dispatch(storeStockInfo(res.data.results.data));
//         return res.data.results.data;
//       })
//       .catch((err) => console.log(err));
//   } catch (error) {
//     throw new Error(`CryptoCompare request error: ${error.status}`);
//   }
// }

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, symbol) {
  const short = `${symbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

// export function parseFullSymbol(fullSymbol) {
//   const match = fullSymbol.match(/^(\w+):(\w+)$/);
//   console.log("Parse", match);
//   if (!match) {
//     return null;
//   }

//   return {
//     exchange: match[1],
//     symbol: match[2],
//   };
// }
