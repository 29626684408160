import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { findIndex, cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { addToWatchlist, getWatchList } from "../../../api";

const Group = ({
  handleCheckGroup,
  groupId,
  name,
  hoverColor,
  stocks,
  order,
  priority,
  color,
  stockInfo,
  setCheckedGroups,
  activeGroup,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleClick = () => {
    handleCheckGroup(groupId);
    setIsChecked(!isChecked);
  };
  const isAlreadyInTheGroup = () => {
    if (stocks.indexOf(stockInfo) !== -1) {
      setIsChecked(true);
      setCheckedGroups((cg) => [...cg, groupId]);
    }
  };
  useEffect(() => {
    isAlreadyInTheGroup();
  }, [activeGroup]);
  return (
    <div
      onClick={handleClick}
      className={`w-[150px] p-5 bg-gray-700 h-30 m-2 border-l-4 relative hover:cursor-pointer `}
      style={{ borderLeftColor: color }}
    >
      {isChecked ? (
        <div className="w-[20px] h-[20px] absolute bottom-0 right-0">
          <BsCheck fill="#fff" />
        </div>
      ) : null}

      <p className="group-name text-sm text-white">{name}</p>
    </div>
  );
};

const WatchlistModal = ({
  stockInfo,
  isOpen,
  onClose,
  setActiveGroup,
  activeGroup,
  groupList,
  setGroupList
}) => {
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getWatchList().then(setGroups);
  }, []);
  useEffect(() => {
    setGroups(groupList)
  }, [activeGroup, groupList]);

  const handleCheckGroup = (groupId) => {
    const index = checkedGroups.indexOf(groupId);
    const newCheckedGroups = cloneDeep(checkedGroups);
    if (index !== -1) {
      newCheckedGroups.splice(index, 1);
    } else {
      newCheckedGroups.push(groupId);
    }
    setCheckedGroups(newCheckedGroups);
  };

  const handleAddStockToWatchlist = async () => {
    let nameArray = [];
    checkedGroups.map((gid) => {
      if (
        !groups[findIndex(groups, { _id: gid })].stocks.includes(stockInfo._id)
      ) {
        nameArray.push(groups[findIndex(groups, { _id: gid })].name);
      }
    });
    const form = {
      email: "mytrade_test@gmail.com",
      name: nameArray,
      symbol: stockInfo.symbol,
    };
    const resp = await addToWatchlist(form);
    if (resp.status === "success") {
      onClose();
      const newStocks = cloneDeep(activeGroup.stocks);
      if (activeGroup.stocks.indexOf(stockInfo._id) === -1 && checkedGroups.indexOf(activeGroup._id) !== -1) {
        newStocks.push(stockInfo._id);
      }
      setActiveGroup({...activeGroup, stocks: newStocks});
      const newGroupList = cloneDeep(groupList).map((grp) => {
        if (grp._id === activeGroup._id) {
          grp.stocks = newStocks;
        }
        return grp;
      })
      setGroupList(newGroupList);
    }
  };

  return (
    <div className="w-full">
      <Modal
        className="dark"
        size={"xl"}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setCheckedGroups([]);
        }}
      >
        <ModalOverlay />
        <ModalContent w={1000}>
          <ModalHeader className="flex items-baseline">
            <p className="text-lg font-bold pr-1">
              Add {stockInfo.symbol} to Watch list
            </p>
            <p className="text-lg font-bold">- Select group</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex flex-wrap justify-around items-center">
              {groups.map((group) => (
                <Group
                  stockInfo={stockInfo._id}
                  key={group._id}
                  groupId={group._id}
                  stocks={group.stocks}
                  name={group.name}
                  priority={group.priority}
                  color={group.color}
                  hoverColor={group.hoverColor}
                  handleCheckGroup={handleCheckGroup}
                  setCheckedGroups={setCheckedGroups}
                  activeGroup={activeGroup}
                />
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={handleAddStockToWatchlist}>
              Add to watchlist
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default WatchlistModal;
