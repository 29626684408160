import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useToast,
} from '@chakra-ui/react';
import React from "react";
import { deleteWatchlist } from '../../api';

export const DeleteWatchlistGroupAlertDialog = ({ activeGroup, isOpen, onClose, onSuccess }) => {
    const toast = useToast();
    const cancelRef = React.useRef();

    const handleDeleteWatchlistGroup = async () => {
            const watchlistPayload = { _id: activeGroup._id, name: activeGroup.name, email: "mytrade_test@gmail.com" };
            const resp = await deleteWatchlist(watchlistPayload);

            toast({
                title: 'Delete Watchlist Group',
                description: resp.message,
                status: resp.status,
                duration: 3000,
                isClosable: true,
            }); 

            if (resp.status === 'success') {
                onClose();
                onSuccess();
            }
    }
  
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete Watchlist Group
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={handleDeleteWatchlistGroup} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }