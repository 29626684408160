import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header";
import TickerContainer from "../TickerContainer";
import { TVChartContainer } from "../TVChartContainer/index.js";

import { SocketContext, socket } from "../../context/socket";
import StockInfo from "../StockInfo";
import { getAllStocks, getWatchList } from "../../api";

import { findIndex, orderBy } from "lodash";
import StockAssets from "../StockAssets";
import WatchlistPicker from "../WatchlistPicker";
import BidAndAsk from "../BidAndAsk";
import Transactions from "../Transactions";
import { Button, CircularProgress } from "@chakra-ui/react";
import { ScreenerModal } from "../Screener";

const Dashboard = ({ isDark, setIsDark }) => {
  const [allStocks, setAllStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState({
    symbol: "FAF",
  });
  const [screenerOpen, setScreenerOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [isActiveGroupInit, setIsActiveGroupInit] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);
  const [watchlist, setWatchlist] = useState([]);

  const allStocksRef = useRef(null);
  const tickerRef = useRef(null);
  const watchlistRef = useRef(null);
  const stockInfoRef = useRef(null);
  const transactionRef = useRef(null);

  const handleScreenerOpen = () => {
    setScreenerOpen(true);
  }

  const handleScreenerClose = () => {
    setScreenerOpen(false);
  }

  const handleIncomingTransaction = useCallback(
    async (data) => {
      if (allStocks.length > 0) {
        const index = findIndex(allStocks, {
          symbol: data.symbol,
        });
        if (index !== -1) {
          const newTicker = {
            ...allStocks[index],
            stock_id: data.stock_id,
            symbol: data.symbol,
            value: data.last_transacted_price * data.last_transacted_volume,
            price_change: data.price_change,
            price: data.last_transacted_price,
            trades: data.last_transacted_volume,
            time_server: data.time_server,
            transaction_number: data.transaction_number
          };
          allStocksRef.current.handleNewTransaction(newTicker);
          tickerRef.current.handleNewTransaction(newTicker);
          watchlistRef.current.handleNewTransaction(newTicker);
          stockInfoRef.current.handleNewTransaction(newTicker);
          transactionRef.current.handleNewTransaction(newTicker);
        }
      }
    },
    [allStocks]
  );

  useEffect(() => {
    console.log("HI!!!")
    if (allStocks.length > 0) {
      const index = findIndex(allStocks, {
        symbol: selectedStock.symbol,
      });
      setSelectedStock(allStocks[index]);
    }
  }, [selectedStock, allStocks]);

  useEffect(() => {
    // subscribe to socket events
    socket.on("all:update", handleIncomingTransaction);
    return () => {
      // unbind all event handlers used in this component before the component is destroyed
      socket.off("all:update", handleIncomingTransaction);
    };
  }, [handleIncomingTransaction]);

  useEffect(() => {
    if (activeGroup !== null && activeGroup) {
      const stocks = activeGroup.stocks;
      const watchlistArray = [];
      stocks.map((s) => {
        watchlistArray.push(allStocks[findIndex(allStocks, { _id: s })]);
      });
      setWatchlist(watchlistArray);
    }
  }, [activeGroup, allStocks]);

  useEffect(() => {
    if (!isActiveGroupInit && groupList.length > 0) {
      setActiveGroup(groupList[0]);
      setIsActiveGroupInit(true);
    }
  }, [groupList, isActiveGroupInit]);

  useEffect(() => {
    (async () => {
      const a = await getAllStocks(0);
      setAllStocks(orderBy(a, ["symbol"], ["asc"]));
      await getWatchList().then(setGroupList);
    })();
  }, []);

  return (
    <div className="bg-[#F0F3FA] dark:bg-dark-bg grid-flow-row min-h-screen w-screen grid grid-cols-2 grid-rows-20 md:grid-cols-3 md:grid-rows-18 xl:grid-cols-9 xl:grid-rows-11 gap-1 xl:auto-rows-fr">
      <div className="col-span-2 row-span-1 md:col-span-3 xl:col-span-full ">
        <Header isDark={isDark} setIsDark={setIsDark} />
      </div>
      <div className="md:flex md:hidden col-span-full xl:col-span-full xl:flex xl:row-start-2 overflow-hidden">
        <TickerContainer groupList={groupList} ref={tickerRef} />
      </div>
      <div className="z-20 col-span-2 row-span-2 md:row-span-1 md:col-span-3 xl:col-span-7 xl:row-span-1 xl:row-start-3">
        <StockInfo
          selectedStock={selectedStock}
          setSelectedStock={setSelectedStock}
          allStocks={allStocks}
          ref={stockInfoRef}
          isDark={isDark}
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          groupList={groupList}
          setGroupList={setGroupList}
          isLoaded={allStocks.length > 0}
        />
      </div>
      <div className="row-span-11 col-span-2 md:col-span-2 md:row-span-9 xl:row-span-8 xl:col-span-5 xl:row-start-4 ">
        {/* Chart */}
        <TVChartContainer
          id={"tv_chart_container"}
          {...selectedStock}
          setSelectedStock={setSelectedStock}
          isDark={isDark}
        />
      </div>
      <div className="hidden md:flex md:col-span-1 md:row-span-4 xl:row-span-4 xl:col-span-2 xl:col-start-8 xl:row-start-3">
        <div className="flex flex-col w-full relative bg-light-container-bg dark:bg-gray-900 text-white">
          <div className="h-10 bg-light-container-bg dark:bg-gray-900 text-dark-bg dark:text-white flex flex-row justify-between items-center px-2 text-sm uppercase">
            Watchlist
            {activeGroup ? (
              <>
                <WatchlistPicker
                  groupList={groupList}
                  activeGroup={activeGroup}
                  setActiveGroup={setActiveGroup}
                  setGroupList={setGroupList}
                />
              </>
            ) : null}
          </div>
          {allStocks.length > 0 ? (
            <StockAssets
              stocks={watchlist}
              setSelectedStock={setSelectedStock}
              selectedStock={selectedStock}
              ref={watchlistRef}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
              groupList={groupList}
              setGroupList={setGroupList}
              />
          ) : <div className="flex items-center justify-center h-full">
            <CircularProgress isIndeterminate color="green.400" />
          </div>}
        </div>
      </div>
      <div className="hidden md:flex md:col-span-1 md:row-span-10 xl:row-span-7 xl:col-span-2 xl:col-start-8">
        <div className="flex flex-col w-full relative bg-light-container-bg dark:bg-container-bg text-dark-bg dark:text-white">
          <div className="h-10 bg-light-container-bg dark:bg-gray-900 text-dark-bg dark:text-white flex flex-row items-center px-2 text-sm uppercase">
            All Stocks
          </div>
          {allStocks.length > 0 ? (
            <StockAssets
              stocks={allStocks}
              setSelectedStock={setSelectedStock}
              selectedStock={selectedStock}
              ref={allStocksRef}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
              groupList={groupList}
              setGroupList={setGroupList}
            />
          ) : <div className="flex items-center justify-center h-full">
            <CircularProgress isIndeterminate color="green.400" />
          </div>}
        </div>
      </div>
      <div className="row-span-2 col-span-2 md:col-span-2 md:row-span-2 xl:row-span-2 xl:col-span-5 xl:row-start-8 xl:col-start-3">
        <div className="w-full h-full bg-light-container-bg dark:bg-gray-900">
          <div className="h-10 bg-light-container-bg dark:bg-gray-900 text-dark-bg dark:text-white flex flex-row items-center px-2 text-sm uppercase">
            Screener
          </div>
          <div>
            <Button ml={"3"} onClick={handleScreenerOpen}>
              Editor
            </Button>
            <ScreenerModal isOpen={screenerOpen} onClose={handleScreenerClose} />
          </div>
        </div>
      </div>
      <div className="col-span-1 row-span-7 md:row-span-5 xl:row-span-5 xl:col-span-2 xl:col-start-1 xl:row-start-4">
        <div className="w-full h-full bg-light-container-bg dark:bg-gray-900">
          <div className="h-10 bg-light-container-bg dark:bg-gray-900 text-dark-bg dark:text-white flex flex-row items-center px-2 text-sm uppercase">
            Bid and Ask
          </div>
          <div>
            <BidAndAsk symbol={selectedStock.symbol} />
          </div>
        </div>
      </div>
      <div className="col-span-1 row-span-7 md:row-span-5 xl:col-span-2 xl:row-span-7 xl:col-start-1 xl:row-start-7">
        <div className="w-full h-full bg-light-container-bg dark:bg-container-bg">
          <div className="h-10 bg-light-container-bg dark:bg-gray-900 text-dark-bg dark:text-white flex flex-row items-center px-2 text-sm uppercase">
            Transactions
          </div>
          <div>
            <Transactions selectedStock={selectedStock} ref={transactionRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Dashboard);
