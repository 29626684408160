import React from "react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";

const Header = ({isDark, setIsDark}) => {
  const toggleIsDark = () => {
    setIsDark(!isDark)
  }
  return (
    <div className="w-full h-full text-dark-bg relative dark:text-white text-center flex flex-row items-center justify-between px-8">
      <div className="w-[50%]"><img src="/my-trade-logo.png" alt={'logo'} className={'w-[30%] md:w-[20%] xl:w-[10%]'} /></div>

      <div className="flex items-center ">
        {
          isDark?<SunIcon className={'hover:cursor-pointer'} onClick={toggleIsDark} />: <MoonIcon className={'hover:cursor-pointer'} onClick={toggleIsDark} />
        }
        <h1 className="px-5">Rainier Punzalan</h1>
      </div>
    </div>
  );
};

export default Header;
