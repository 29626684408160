import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Switch, useDisclosure } from "@chakra-ui/react";
import { findIndex } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { BsGear } from "react-icons/bs";
import { fixNumber } from "../../utils/price-parser";
import TickerOptions from "../TickerOptions";
import Ticker from "./Ticker";

const TickerContainer = ({ groupList }, ref) => {
  const [tickers, setTickers] = useState([]);
  const [pausedTickers, setPausedTickers] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isAll, setIsAll] = useState(true);
  const [stocks, setStocks] = useState([]);
  const {isOpen, onOpen, onClose} = useDisclosure();

  const filterTickers = (transaction) => {
    setTickers((prev) => [...prev, transaction]);
  };

  useImperativeHandle(ref, () => ({
    handleNewTransaction: (transaction) => {
      if (transaction !== null) {
        if (!isAll) {
          if (stocks.includes(transaction._id)) {
            filterTickers(transaction);
          }
        } else {
          filterTickers(transaction);
        }
      }
    },
  }));

  useEffect(() => {
    let stockList = [];
    groupList.map((grp) => {
      stockList = [...new Set([...stockList, ...grp.stocks])];
    });
    setStocks(stockList);
  }, [groupList]);

  const handleHover = () => {
    setPausedTickers(tickers);
    setIsPaused(true);
  };
  const handleHoverOff = () => {
    setIsPaused(false);
  };

  return (
    <div className="w-full h-full text-dark-bg dark:text-white text-center flex flex-row-reverse">
      <TickerOptions
        isAll={isAll}
        setIsAll={setIsAll}
        isOpen={isOpen}
        onClose={onClose}
      />
      <div
        className="h-full flex"
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverOff}
      >
        {isPaused
          ? pausedTickers
              .slice(-30, -1)
              .map(({ symbol, value, price, price_change }, index) => (
                <Ticker
                  key={index}
                  value={fixNumber(value)}
                  price_change={price_change}
                  price={fixNumber(price)}
                  symbol={symbol}
                />
              ))
          : tickers
              .slice(-30, -1)
              .map(({ symbol, value, price, price_change }, index) => (
                <Ticker
                  key={index}
                  value={fixNumber(value)}
                  price_change={price_change}
                  price={fixNumber(price)}
                  symbol={symbol}
                />
              ))}
      </div>
      <div
        onClick={onOpen}
        className="absolute h-[70px] hover:cursor-pointer right-0 bg-dark-bg text-white dark:bg-gray-300 dark:text-dark-bg w-[30px] flex justify-center items-center "
      >
        <BsGear />
      </div>
    </div>
  );
};

export default forwardRef(TickerContainer);
