import { Modal, useDisclosure } from '@chakra-ui/react'
import { findIndex } from 'lodash'
import React, {useRef, useEffect} from 'react'
import { BsPlusCircle, BsPencil } from 'react-icons/bs'
import AddWatchlistGroupModal from '../AddWatchlistGroupModal'
import EditWatchlistNameModal from '../EditWatchlistNameModal'

const WatchlistPicker = ({
  groupList,
  activeGroup,
  setActiveGroup,
  setGroupList,
}) => {
  const handleGroupChange = (e) => {
    // setActiveGroup()
    const index = findIndex(groupList, { _id: e.target.value });
    setActiveGroup(groupList[index]);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: editOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();

  return (
    <>
      <EditWatchlistNameModal
        isOpen={editOpen}
        onClose={editOnClose}
        activeGroup={activeGroup}
        setActiveGroup={setActiveGroup}
		    groupList={groupList}
        setGroupList={setGroupList}
    />
      <AddWatchlistGroupModal
        isOpen={isOpen}
        onClose={onClose}
        setGroupList={setGroupList}
		groupList={groupList}
      />
      <BsPlusCircle className="hover:cursor-pointer" onClick={onOpen} />
      <select
        className="bg-light-container-bg dark:bg-dark-bg"
        defaultValue={activeGroup._id}
        onChange={handleGroupChange}
      >
        {groupList.map((g) => (
          <option key={g._id} value={g._id}>
            {g.name}
          </option>
        ))}
      </select>
      <BsPencil className="hover:cursor-pointer" onClick={editOnOpen} />
    </>
  );
};

export default WatchlistPicker