import { useDisclosure } from "@chakra-ui/react";
import { findIndex, orderBy, cloneDeep, update } from "lodash";
import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  BsXDiamond,
} from "react-icons/bs";
import { removeWatchListItem } from "../../api/index.js";
import StockAsset from "../StockAsset.js";
import TableHeader from "./TableHeader";
import WatchlistModal from "./WatchlistModal/index.js";

const StockAssets = (
  { stocks, setSelectedStock, selectedStock, activeGroup, setActiveGroup, groupList, setGroupList },
  ref
) => {
  const stockRefs = useRef([]);
  const [stockArray, setStockArray] = useState(stocks);
  const stocksN = stockArray.length;
  const [modalStock, setModalStock] = useState([]);
  const [sortBy, setSortBy] = useState("symbol");
  const [sortDirection, setSortDirection] = useState(1); // 1==asc; 0==desc
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (stockRefs.current.length !== stocksN) {
    stockRefs.current = Array(stocksN)
      .fill()
      .map((_, i) => stockRefs.current[i] || createRef());
  }
  const handleUpdate = (index, data) => {
    stockRefs.current[index].updateStockAsset(data);
  };

  const assetClicked = (asset) => {
    if (asset.symbol !== selectedStock.symbol) setSelectedStock(asset);
  };

  const sort = ({ sortBy: newSortBy, sortDirection }) => {
    if (newSortBy != sortBy) {
      sortDirection = true;
    }
    let newList = orderBy(
      stockArray,
      newSortBy,
      sortDirection ? "asc" : "desc"
    );
    setStockArray([...newList]);
    setSortBy(newSortBy);
    setSortDirection(sortDirection);
  };

  useImperativeHandle(ref, () => ({
    handleNewTransaction: (newTransaction) => {
      if (newTransaction !== null) {
        const i = findIndex(stockArray, { symbol: newTransaction.symbol });
        if (i != -1) {
          handleUpdate(i, newTransaction);
        }
      }
    },
  }));

  const showWatchlistModal = (stockInfo) => {
    setModalStock(stockInfo);
    onOpen();
  };

  const handleRemoveFromWatchlist = async (stockInfo) => {
    const form = {
      email: "mytrade_test@gmail.com",
      name: [activeGroup.name],
      symbol: stockInfo.symbol,
    };
    await removeWatchListItem(form)
    .then((resp)=>{
      console.log(resp)
      const updatedGroupList = cloneDeep(groupList);
      const updatedActiveGroup = cloneDeep(activeGroup);
      updatedActiveGroup.stocks = updatedActiveGroup.stocks.filter(s => s !== stockInfo._id);

      const index = findIndex(groupList, { _id: activeGroup._id });
      updatedGroupList[index] = updatedActiveGroup;

      setActiveGroup(updatedActiveGroup);
      setGroupList(updatedGroupList);
    });
  }

  useEffect(() => {
    setStockArray(stocks);
  }, [stocks]);

  return (
    <>
      {modalStock && groupList ? (
        <WatchlistModal
          stockInfo={modalStock}
          isOpen={isOpen}
          onClose={onClose}
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
          groupList={groupList}
          setGroupList={setGroupList}
        />
      ) : null}
      <div
        style={{ height: window.innerHeight / 2.2 }}
        className="flex flex-col w-full overflow-auto"
      >
        {stockArray.length > 0 ? (
          <table className="w-full text-sm text-left">
            <thead className="text-tiny text-dark-bg dark:text-white uppercase top-0 sticky bg-light-container-bg dark:bg-gray-700">
              <tr className="">
                <th></th>
                <TableHeader
                  className="flex items-center"
                  label={"Stock"}
                  sortKey={"symbol"}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  sort={sort}
                />
                <TableHeader
                  label={"Last"}
                  sortKey={"curr_price"}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  sort={sort}
                />
                <TableHeader
                  label={"Chg(%)"}
                  sortKey={"price_change"}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  sort={sort}
                />
                <TableHeader
                  label={"Value"}
                  sortKey={"market_cap"}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  sort={sort}
                />
                <TableHeader
                  label={"Trades"}
                  sortKey={"trades"}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  sort={sort}
                />
              </tr>
            </thead>
            <tbody className="text-tiny">
              {stockArray.map((s, index) => {
                return (
                  <StockAsset
                    stock={s}
                    ref={(stock) => (stockRefs.current[index] = stock)}
                    key={index}
                    handleClick={assetClicked}
                    handleAddWatchlist={() => showWatchlistModal(s)}
                    activeGroup={activeGroup}
                    setActiveGroup={setActiveGroup}
                    handleRemoveFromWatchlist={handleRemoveFromWatchlist}
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col justify-center items-center h-full">
            <BsXDiamond />
            <p className="font-xl font-bold py-5">No Stocks Found</p>
          </div>
        )}
      </div>
    </>
  );
};

export default forwardRef(StockAssets);
