import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Switch } from "@chakra-ui/react";
import React from "react";

const TickerOptions = ({ isOpen, onClose, isAll, setIsAll }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <div className="w-full flex justify-evenly p-20 align-center">
		  	<p>All Stocks</p>
            <Switch isChecked={isAll} onChange={()=>{setIsAll(!isAll)}} />
		  	<p>Watchlist</p>
          </div>
          <ModalCloseButton />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TickerOptions;
